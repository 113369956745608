<template>
  <div class="tower-grid__inner">
    <div class="tower-grid__wrapper">
      <div class="tower-grid">
        <div class="tower-grid__row"
          :class="{ disabled: game && ((game.active && game.state.step !== i - 1) || (!game.active && game.state.step >= i + 1)) }"
          v-for="i in 10" :key="`tower-row-${i}`">
          <div class="tower-grid__col" v-for="j in 6 - (noRate ? 1 : 0)"
            :class="{ rate: j === 1 && !noRate, col: j !== 1 || noRate }" :key="`tower-row-${i}-${j}`">
            <div v-if="j === 1 && !noRate" class="tower-grid__rate" :class="{
              sm: getRateInt(i) > 99999,
              active:
                game &&
                ((!game.active && game.coeff && game.state.step === i) ||
                  (!game.active && !game.coeff && game.state.step === i - 1) ||
                  (game.active && game.state.step === i - 1) ||
                  (game.state.step === 9 && i === 10)),
              disabled: game && game.state.step > i - 1,
            }">
              x{{ getRate(i) }}
            </div>
            <TowerButton v-else :index="j - 1" :opened="noRate || (game && (game.state.step > i - 1 || !game.active))"
              :visible="noRate" :active="game?.active && game.state.step === i - 1" :symbol="checkIsSymbol(i - 1, j - 1)"
              :selected="checkIsSelected(i - 1, j - 1)" />
          </div>
        </div>
      </div>
      <template v-if="!simple">
        <img src="../assets/images/tower-bg.svg?url" class="tower-scene__tower" alt="" />
        <img src="../assets/images/dark/tower-bg.svg?url" class="tower-scene__tower dark" alt="" />

        <img src="../assets/images/tower-top.svg?url" class="tower-scene__top" alt="" />
        <img src="../assets/images/dark/tower-top.svg?url" class="tower-scene__top dark" alt="" />

        <img src="../assets/images/cloud-left.svg?url" class="tower-scene__cloud-left" alt="" />
        <img src="../assets/images/dark/cloud-left.svg?url" class="tower-scene__cloud-left dark" alt="" />
        <img src="../assets/images/cloud-right.svg?url" class="tower-scene__cloud-right" alt="" />
        <img src="../assets/images/dark/cloud-right.svg?url" class="tower-scene__cloud-right dark" alt="" />
        <img src="../assets/images/center-front.svg?url" class="tower-scene__center-front" alt="" />
        <img src="../assets/images/dark/center-front.svg?url" class="tower-scene__center-front dark" alt="" />
      </template>
    </div>

    <template v-if="!simple">
      <img src="../assets/images/rock-left.svg?url" class="tower-scene__rock-left" alt="" />
      <img src="../assets/images/dark/rock-left.svg?url" class="tower-scene__rock-left dark" alt="" />
      <img src="../assets/images/rock-right.svg?url" class="tower-scene__rock-right" alt="" />
      <img src="../assets/images/dark/rock-right.svg?url" class="tower-scene__rock-right dark" alt="" />
      <img src="../assets/images/center-back.svg?url" class="tower-scene__center-back" alt="" />
      <img src="../assets/images/dark/center-back.svg?url" class="tower-scene__center-back dark" alt="" /></template>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "~~/store";
import TowerButton from "./TowerButton.vue";
import { storeToRefs } from "pinia";
import { useTowerStore } from "../tower.store";
import { TowerGame } from "../tower.model";
import { formatNumber, round } from "@/helpers";

const props = defineProps<{
  innerGame?: TowerGame;
  simple?: boolean;
  noRate?: boolean;
  mines?: number[][];
}>();

const store = useStore();
const { dark } = storeToRefs(store);

const towerStore = useTowerStore();
const { game: localGame, form, rtp } = storeToRefs(towerStore);

const game = computed(() => props.innerGame || localGame.value);

function checkIsSymbol(i: number, j: number) {
  if (props.mines?.length) {
    const row = Object.values(props.mines[i]);

    return row.includes(j + 1) ? "shield" : "emeralds";
  }
  if (!game.value) return;
  const col = game.value.state.history[i];
  if (!col && game.value.active) return;
  const mines = game.value.state.mines[i];
  const array = Object.values(mines);

  return array.includes(j) ? "shield" : "emeralds";
}
function checkIsSelected(i: number, j: number) {
  if (!game.value) return;
  const col = game.value.state.history[i];

  return col && col.includes(j);
}
function getRate(i: number) {
  const val = getRateInt(i);
  return formatNumber(val, val > 99_999 ? 0 : 2);
}
function getRateInt(i: number) {
  if (props.innerGame?.state?.xs?.length) return props.innerGame.state.xs[i - 1];
  const myGame = game.value;

  const mines = (myGame?.active && myGame?.state?.minesCount) || form.value.mines;

  const rate = Math.floor((rtp.value || 100) * Math.pow(5 / (5 - mines), i)) / 100;
  return round(rate);
}
</script>

<style lang="scss">
.tower-scene {
  &__tower {
    position: absolute;
    bottom: -2%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 103%;
    height: auto;
    border-top-left-radius: var(--b-radius);
    border-top-right-radius: var(--b-radius);
  }

  &__top {
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 1;
    width: 107%;
    height: auto;
  }

  &__cloud-left {
    position: absolute;
    top: -30px;
    left: -58%;
    z-index: 0;
    width: auto;
    height: auto;

    @media screen and (max-width: 920px) {
      left: -40%;
      top: -100px;
    }
  }

  &__cloud-right {
    position: absolute;
    top: -19%;
    right: -74%;
    z-index: 0;
    width: auto;
    height: auto;

    @media screen and (max-width: 920px) {
      right: -50%;
      top: -110px;
    }
  }

  &__rock-right {
    position: absolute;
    bottom: 0%;
    right: -2%;
    z-index: 0;
    width: auto;
    height: auto;
  }

  &__rock-left {
    position: absolute;
    bottom: 0%;
    left: -1%;
    z-index: 0;
    width: auto;
    height: auto;
  }

  &__center-back {
    position: absolute;
    bottom: -12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    width: auto;
    height: auto;
  }

  &__center-front {
    position: absolute;
    bottom: -9px;
    left: -37.12px;
    z-index: 4;
    width: auto;
    height: auto;
  }
}

.tower-grid {
  display: flex;
  flex-direction: column-reverse;
  gap: 6px;
  border-radius: 6px;
  border: 1px solid #7e8db4;
  background: #777a99;
  padding: 6px;
  position: relative;
  z-index: 2;

  @include mobile {
    padding: 0;
    background: transparent;
    border: none;
  }

  &__wrapper {
    position: relative;
    z-index: 6;

    @include mobile {
      width: 100%;
    }

    &>img {
      display: block;

      &.dark {
        display: none;
      }
    }

    @media screen and (max-width: 920px) {
      transform: scale(0.9);
      transform-origin: center bottom;

      margin-top: -40px;
    }

    @media screen and (max-width: 860px) {
      transform: scale(0.8);
      transform-origin: center bottom;
      margin-top: -100px;
    }

    @include mobile {
      transform: none;
      margin-top: 0px;
    }
  }

  &__inner {
    display: flex;

    img {
      pointer-events: none;
    }

    @include mobile {
      width: 100%;
    }

    &>img {
      display: block;

      &.dark {
        display: none;
      }
    }
  }

  &__row {
    display: flex;
    gap: 6px;

    &.disabled {

      &,
      * {
        pointer-events: none !important;
        transition: none !important;
      }
    }
  }

  &__col {
    button {
      height: 100%;
      width: 100%;
    }

    &.rate {
      flex-shrink: 0;
      width: 74px;
      height: 36px;
      margin-right: 4px;

      @include mobile {
        width: 54px;
        height: 34px;
        margin-right: 2px;
      }
    }

    &.col {
      width: 80px;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__rate {
    border-radius: var(--b-radius);
    background: #535574;
    color: #ffffff;

    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;

    @media screen and (max-width: 760px) {
      font-size: 10px;
      font-weight: 700;
    }

    &.disabled {
      color: #ffffff;
      background: #3b3e5a;
    }

    &.active {
      background: linear-gradient(90deg, #f4a202 0%, #fac257 100%) !important;
      color: #2c3034 !important;
    }
  }
}

@include dark {
  .tower-grid {
    border: 1px solid #575d69;
    background: #373c49;

    @include mobile {
      background: transparent;
      border: none;
    }

    &__rate {
      background: #424b5b;

      &.disabled {
        background: #2b303d;
      }
    }

    &__wrapper,
    &__inner {
      &>img {
        display: none;
      }

      img+img.dark {
        display: block;
      }
    }
  }

  .tower-scene {
    &__bg {
      display: none;

      &.dark {
        display: block;
      }
    }
  }
}

.tower-grid {

  &__wrapper,
  &__inner {
    &>img {
      @include mobile {
        display: none !important;
      }
    }
  }
}
</style>
