import { defineStore } from "pinia";
import { TowerGame, TowerSeed } from "./tower.model";

export const useTowerStore = defineStore('tower', () => {
    const game = ref<TowerGame>()
    const seed = ref<TowerSeed>()
    const rtp = ref(95)
    const isInited = ref(false)
    const isLoading = ref(false)

    const form = ref({
        amount: 1,
        mines: 1,
    });

    function onReset() {
        game.value = undefined
        form.value.amount = 1
        form.value.mines = 1

    }


    return {
        game,
        isInited,
        seed,
        form,
        rtp,
        isLoading,
        onReset
    }
})