import { TowerCreateResponseDto, TowerInitResponseDto } from "./tower.dto"

export function createGame(body: {
    amount: number,
    mines: number
}) {
    const { $axios } = useNuxtApp()
    return $axios.$post<TowerCreateResponseDto>('games/tower/create', body)
}

export function getGame() {
    const { $axios } = useNuxtApp()
    return $axios.$get<TowerInitResponseDto>('games/tower/init')
}

export function placeGame(body: {
    place: number
}) {
    const { $axios } = useNuxtApp()
    return $axios.$post<TowerInitResponseDto>('games/tower/place', body)
}
export function collectGame() {
    const { $axios } = useNuxtApp()
    return $axios.$get<TowerInitResponseDto>('games/tower/collect')
}