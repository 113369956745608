<template>
  <button @click="onSubmit" type="button" class="tower-button"
    :class="{ active, opened, visible, selected, shield: symbol === 'shield', emeralds: symbol === 'emeralds' }">
    <IconEmpty class="empty" />
    <IconCircle class="circle" />

    <div v-show="opened && symbol === 'emeralds'" class="content">
      <div v-if="selected && symbol === 'emeralds'" class="overlay win"></div>
      <img class="emeralds" src="../assets/images/emeralds.svg?url" />
    </div>
    <div v-show="opened && symbol === 'shield'" class="content">
      <div v-if="selected && symbol === 'shield'" class="overlay"></div>
      <img class="shield" src="../assets/images/shield.svg?url" />
    </div>
  </button>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { placeGame } from "../tower.service";
import { useTowerStore } from "../tower.store";
import IconEmpty from "../assets/images/empty.svg?component";
import IconCircle from "../assets/images/circle.svg?component";

const props = defineProps<{
  opened?: boolean;
  symbol?: string;
  selected?: boolean;
  visible?: boolean;
  active?: boolean;
  index: number;
}>();

const store = useTowerStore();
const { game, isLoading } = storeToRefs(store);

const { $auth } = useNuxtApp();

function onSubmit() {
  if (!game.value?.active || isLoading.value) return;
  isLoading.value = true;
  placeGame({
    place: props.index,
  })
    .then(({ response }) => {
      Object.assign(game.value!, response.game);

      if (response.balance) $auth.user.money = parseFloat(response.balance);
    })
    .finally(() => {
      isLoading.value = false;
    });
}
</script>

<style lang="scss">
.tower-button {
  border-radius: var(--b-radius);
  background: #535574;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  .content {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay {
      // background-size: 2480px;
      width: 128px;
      height: 128px;
      // margin: -37px 0 0 -39px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.6);
      background-image: url("../assets/images/sprite/loose.png");
      animation: mines_sprite 0.5s steps(41) 0.1s forwards;

      &.win {
        background-image: url("../assets/images/sprite/win.png");
        animation: mines_sprite 0.5s steps(50) 0.1s forwards;
      }
    }

    &>img {
      width: auto;
    }
  }

  img {
    @include mobile {
      max-height: 70%;
    }
  }

  img,
  svg {
    display: none;
  }

  .emeralds {
    height: 30px;
  }

  .shield {
    height: 26px;
  }

  .empty {
    color: #afcfff;
    opacity: 0.5;
    height: 21px;
    width: auto;
  }

  .circle {
    height: 14px;
    width: auto;
  }

  &:not(.opened):not(.active) .empty,
  &.active .circle,
  &.opened.shield .shield,
  &.opened.emeralds .emeralds {
    display: block;
  }

  &.active {
    background: #8f93bc;
    color: #535574;
    transition: 0.2s;

    &:hover,
    &:focus {
      background: #a5aad8;
    }

    svg {
      opacity: 0.5;
      transition: 0.2s;
    }
  }

  &.opened {
    background: #535574;
    cursor: not-allowed;

    &:not(.selected):not(.visible) {
      opacity: 0.6;

      &.shield {
        // background: radial-gradient(86.07% 116.11% at 14.28% 11.22%, rgba(255, 85, 126, 0.3) 0%, rgba(226, 8, 8, 0.18) 100%), #f4f2ff;
      }
    }
  }

  &.opened.selected {
    background: #3b3e5a;
  }
}

@include dark {
  .tower-button {
    background: #424b5b;

    &.active {
      background: #596984;
      color: #849cc1;

      &:hover,
      &:focus {
        background: #6a7fa4;
      }

      svg {
        opacity: 1;
      }
    }

    &.opened {
      cursor: not-allowed;

      &:not(.selected):not(.visible) {
        opacity: 0.4;

        &.shield {
          // background: radial-gradient(86.07% 116.11% at 14.28% 11.22%, rgba(255, 85, 126, 0.2) 0%, rgba(226, 8, 8, 0.12) 100%), linear-gradient(0deg, #424b5b 0%, #424b5b 100%), #000;
        }
      }
    }

    &.opened.selected {
      background: #2b303d;
    }
  }
}

@keyframes mines_sprite {
  0% {
    background-position: 0 0;
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    opacity: 0;
    background-position: 100% 0;
  }
}
</style>
